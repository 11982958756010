import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import { setHoodListSortingType } from '../../state/settings/actions';
import { selectHood } from '../../state/map/actions';
import { setFilersModalIsOpen } from '../../state/modals/actions';
import { sortingFields } from '../../utils/sorting-helper';
import { ratingFields } from '../../utils/rating-helper';
import useIsClient from '../../hooks/use-is-client';

import CityTitle from '../CityTitle';
import TextLink from '../TextLink';
import TextButton from '../TextButton';
import Dropdown from '../Dropdown';
import Skeleton from '../Skeleton';

import style from './MapSubHeader.module.css';

const mapStateToPropsSelector = createSelector(
  (state) => state.settingsReducer,
  (state) => state.mapReducer,
  ({ hoodListSortingType, ratingFilters }, { selectedHood }) => ({
    hoodListSortingType,
    selectedHood,
    currentFiltersAmount: Object.keys(ratingFilters).reduce((acc, key) => {
      if (!ratingFilters[key]) {
        acc--;
      }
      return acc;
    }, ratingFields.length),
  })
);

const MapSubHeader = ({ cityTitle, countryCode }) => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const {
    hoodListSortingType,
    currentFiltersAmount,
    selectedHood,
  } = useSelector(mapStateToPropsSelector);

  const { key, isClient } = useIsClient();

  const onSortingChange = useCallback(
    (value) => dispatch(setHoodListSortingType(value)),
    [dispatch]
  );
  const sortingOptions = sortingFields.map((value) => ({
    value,
    label: intl.formatMessage({ id: `main.hoodsSorting.fields.${value}` }),
  }));

  const openFilersModal = useCallback(
    () => dispatch(setFilersModalIsOpen(true)),
    [dispatch]
  );

  const deselectHood = useCallback(() => dispatch(selectHood(null)), [
    dispatch,
  ]);

  const BackButtonComponent = selectedHood ? TextButton : TextLink;
  const backButtonProps = selectedHood
    ? {
        onClick: deselectHood,
        titleKey: 'main.mapSubHeader.backToCity',
      }
    : {
        linkTo: '/cities/',
        titleKey: 'main.mapSubHeader.backToCities',
      };

  let settingsContainerElement = (
    <div className={style.settingsContainer}>
      <Skeleton className={style.buttonSkeleton} />
      <Skeleton className={style.dropdownSkeleton} />
    </div>
  );
  if (isClient) {
    settingsContainerElement = (
      <div className={style.settingsContainer}>
        <TextButton
          onClick={openFilersModal}
          titleKey={`main.mapSubHeader.${
            currentFiltersAmount === ratingFields.length
              ? 'filtersAll'
              : 'filters'
          }`}
          values={{
            currentFiltersAmount,
          }}
          className={style.filters}
        />
        <Dropdown
          options={sortingOptions}
          defaultLabel={intl.formatHTMLMessage(
            {
              id: `main.mapSubHeader.sorting`,
            },
            {
              sortingType: (
                <b>
                  {intl.formatMessage({
                    id: `main.hoodsSorting.fields.${hoodListSortingType}`,
                  })}
                </b>
              ),
            }
          )}
          defaultValue={hoodListSortingType}
          onChange={onSortingChange}
          containerClassName={style.dropdown}
          optionsClassName={style.options}
        />
      </div>
    );
  }

  return (
    <div className={style.subHeader} key={key}>
      <div className={style.container}>
        <BackButtonComponent {...backButtonProps} className={style.link} />
        <CityTitle
          className={style.cityTitle}
          title={cityTitle}
          countryCode={countryCode}
        />
        {settingsContainerElement}
      </div>
    </div>
  );
};

MapSubHeader.propTypes = {
  cityTitle: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
};

export default MapSubHeader;
