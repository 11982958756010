import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import HoodsListItem from '../HoodsListItem';
import UserMessage from '../UserMessage';
import HoodsListSearchInput from '../HoodsListSearchInput';

import { fetchHoodsByCity } from '../../state/hoods/actions';
import { setHoodListSearchQuery } from '../../state/settings/actions';
import { highlightHood, setListScrollTop } from '../../state/map/actions';

import { getSortedHoodsList } from '../../utils/sorting-helper';
import useComponentDidMount from '../../hooks/use-component-did-mount';

import style from './HoodsList.module.css';

const makeMapStateToPropsSelector = () =>
  createSelector(
    (state) => state.hoodsReducer,
    (state) => state.settingsReducer,
    (state) => state.mapReducer,
    (_, hoodsList, cityTitle) => ({ hoodsList, cityTitle }),
    (
      hoods,
      { hoodListSortingType, searchQuery, isMobileMapHidden },
      { mapIsVisible, listScrollTopObject },
      { hoodsList, cityTitle }
    ) => {
      const { data, fetchHoodsByCitySuccess } = hoods[cityTitle] || {};

      const sortedHoodsList = getSortedHoodsList({
        hoodsList,
        searchQuery,
        ratings: data,
        sortingType: hoodListSortingType,
      });

      return {
        fetchHoodsByCitySuccess,
        sortedHoodsList,
        mapIsVisible,
        isMobileMapHidden,
        searchQuery,
        listScrollTop: listScrollTopObject[cityTitle] || 0,
      };
    }
  );

const HoodsList = ({
  cityTitle,
  hoodsList,
  onSelectHood,
  districtsColors,
  isMobile,
}) => {
  const container = useRef(null);

  const selectDataFromState = useMemo(makeMapStateToPropsSelector, []);
  const {
    fetchHoodsByCitySuccess,
    sortedHoodsList,
    mapIsVisible,
    isMobileMapHidden,
    searchQuery,
    listScrollTop,
  } = useSelector((state) => selectDataFromState(state, hoodsList, cityTitle));

  const dispatch = useDispatch();

  useComponentDidMount(() => {
    if (isMobile) {
      window.scrollTo({ top: listScrollTop });
    } else {
      container.current.scrollTop = listScrollTop;
    }

    if (!fetchHoodsByCitySuccess) {
      dispatch(fetchHoodsByCity({ cityTitle }));
    }
  });

  useEffect(() => {
    const element = container.current;

    return () => {
      dispatch(
        setListScrollTop({
          scrollTop: isMobile
            ? document.documentElement.scrollTop
            : element.scrollTop,
          cityTitle,
        })
      );
    };
  }, [dispatch, isMobile, cityTitle]);

  const onHoverWithDebounce = useCallback(
    debounce((hoveredHoodFeatureId) => {
      if (isMobile && isMobileMapHidden) {
        return;
      }

      dispatch(highlightHood(hoveredHoodFeatureId));
    }, 0),
    [dispatch, isMobile, isMobileMapHidden]
  );

  const showSkeleton =
    fetchHoodsByCitySuccess === undefined ||
    (isMobile ? !isMobileMapHidden && !mapIsVisible : !mapIsVisible);

  return (
    <div className={style.container} ref={container}>
      <HoodsListSearchInput />
      {sortedHoodsList.length ? (
        sortedHoodsList.map((hood) => (
          <HoodsListItem
            key={hood.hoodTitle}
            hood={hood}
            districtColor={districtsColors[hood.districtName]}
            cityTitle={cityTitle}
            onClick={onSelectHood}
            onHover={onHoverWithDebounce}
            showSkeleton={showSkeleton}
            isMobile={isMobile}
          />
        ))
      ) : (
        <UserMessage
          type="searchNotFound"
          values={{ searchQuery }}
          onClick={() => dispatch(setHoodListSearchQuery(''))}
        />
      )}
    </div>
  );
};

HoodsList.propTypes = {
  cityTitle: PropTypes.string.isRequired,
  onSelectHood: PropTypes.func.isRequired,
  hoodsList: PropTypes.array.isRequired,
  districtsColors: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default HoodsList;
