import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { createSelector } from 'reselect';
import { useIntl } from 'gatsby-plugin-intl';

import { setHoodListSearchQuery } from '../../state/settings/actions';

import close from '../../img/icons/close.svg';
import search from '../../img/icons/search.svg';

import style from './HoodsListSearchInput.module.css';

const mapStateToPropsSelector = createSelector(
  (state) => state.settingsReducer,
  ({ searchQuery }) => searchQuery
);

const HoodsListSearchInput = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const searchQuery = useSelector(mapStateToPropsSelector);
  const setHoodListSearchQueryWithDebounce = debounce((value) => {
    dispatch(setHoodListSearchQuery(value));
  }, 0);

  return (
    <div className={style.container}>
      <div className={style.searchButton}>
        <img src={search} alt="search" />
      </div>
      <input
        className={style.input}
        type="text"
        maxLength={80}
        aria-label="search"
        placeholder={intl.formatMessage({
          id: 'main.hoodsListSearchInput.placeholder',
        })}
        value={searchQuery}
        onChange={(event) =>
          setHoodListSearchQueryWithDebounce(event.target.value)
        }
      />
      {searchQuery && (
        /* eslint-disable-next-line */
        <div
          className={style.closeButton}
          onClick={() => setHoodListSearchQueryWithDebounce('')}
        >
          <img src={close} alt="close" />
        </div>
      )}
    </div>
  );
};

export default HoodsListSearchInput;
