import {
  SELECT_HOOD,
  HIGHLIGHT_HOOD,
  SET_MAP_IS_VISIBLE,
  SET_LIST_SCROLL_TOP,
} from './constants';

export const selectHood = (payload) => ({
  type: SELECT_HOOD,
  payload,
});

export const highlightHood = (payload) => ({
  type: HIGHLIGHT_HOOD,
  payload,
});

export const setMapIsVisible = (payload) => ({
  type: SET_MAP_IS_VISIBLE,
  payload,
});

export const setListScrollTop = (payload) => ({
  type: SET_LIST_SCROLL_TOP,
  payload,
});
