import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { createSelector } from 'reselect';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ReactMapGL, {
  LinearInterpolator,
  WebMercatorViewport,
} from 'react-map-gl';
import bbox from '@turf/bbox';
import debounce from 'lodash.debounce';
import 'mapbox-gl/dist/mapbox-gl.css';

import usePrevious from '../../hooks/use-previous';

import { selectHood, setMapIsVisible } from '../../state/map/actions';
import { toggleIsMobileMapHidden } from '../../state/settings/actions';

import getMapStyle from '../../utils/map-style';
import useWindowSize from '../../hooks/use-window-size';

import MapTooltip from '../MapTooltip';
import Loader from '../Loader';
import ToggleSwitch from '../ToggleSwitch';

import style from './Map.module.css';

const DEFAULT_SETTINGS = {
  attributionControl: false,
  dragPan: false,
  dragRotate: false,
  scrollZoom: false,
  touchZoom: false,
  touchRotate: false,
  keyboard: false,
  doubleClickZoom: false,
  width: '100%',
};

const makeMapStateToPropsSelector = () =>
  createSelector(
    (state) => state.mapReducer,
    (state) => state.settingsReducer,
    (_, districts, geoJson) => ({ districts, geoJson }),
    (
      { selectedHood, highlightedHoodFeatureId },
      { isMobileMapHidden },
      { districts, geoJson }
    ) => {
      const mapStyle = getMapStyle({
        geoJson,
        districts,
      });

      return {
        selectedHood,
        highlightedHoodFeatureId,
        mapStyle,
        isMobileMapHidden,
      };
    }
  );

const Map = ({
  geoJson,
  districts,
  viewport: defaultViewport,
  bounds: defaultBounds,
  cityTitle,
}) => {
  const dispatch = useDispatch();

  const selectDataFromState = useMemo(makeMapStateToPropsSelector, []);
  const {
    selectedHood,
    highlightedHoodFeatureId,
    mapStyle,
    isMobileMapHidden,
  } = useSelector((state) => selectDataFromState(state, districts, geoJson));

  const [map, setMap] = useState(null);

  const { width } = useWindowSize();
  const isMobile = width <= 600;
  const shouldMapBeHidden = isMobile && isMobileMapHidden;

  const [isVisible, setIsVisible] = useState(false);
  const [viewport, setViewport] = useState({
    ...defaultViewport,
    bearing: 0,
    pitch: 0,
  });
  const [tooltipData, setTooltipData] = useState(null);

  const prevSelectedHood = usePrevious(selectedHood);

  useEffect(() => {
    return () => {
      dispatch(setMapIsVisible(false));
    };
    /* eslint-disable-next-line */
  }, []);

  const setFeatureState = useCallback(
    (featureId, state) => {
      map.setFeatureState({ source: 'neighborhoods', id: featureId }, state);
    },
    [map]
  );

  const updateMapPosition = useCallback(
    (options) => {
      const {
        isAnimated = false,
        center = null,
        bounds = defaultBounds,
        settings = {},
      } = options || {};
      const newViewport = new WebMercatorViewport(viewport);
      const { longitude, latitude, zoom } = newViewport.fitBounds(
        bounds,
        settings
      );
      let params = {
        ...viewport,
        longitude,
        latitude,
        zoom,
        transitionInterpolator: null,
        transitionDuration: 0,
      };

      if (isAnimated) {
        params = {
          ...params,
          transitionInterpolator: new LinearInterpolator({
            around: center,
          }),
          transitionDuration: 1000,
        };
      }

      setViewport(params);
    },
    [viewport, defaultBounds]
  );

  const prevHighlightedHoodFeatureId = usePrevious(highlightedHoodFeatureId);
  useEffect(() => {
    if (!map) {
      return;
    }

    if (prevHighlightedHoodFeatureId !== null) {
      setFeatureState(prevHighlightedHoodFeatureId, {
        hovered: false,
      });
    }

    if (highlightedHoodFeatureId !== null) {
      setFeatureState(highlightedHoodFeatureId, {
        hovered: true,
      });
    }
  }, [
    map,
    prevHighlightedHoodFeatureId,
    highlightedHoodFeatureId,
    setFeatureState,
  ]);

  useEffect(() => {
    if (!map || !isVisible) {
      return;
    }

    if (prevSelectedHood && !selectedHood) {
      setFeatureState(prevSelectedHood.featureId, {
        clicked: false,
        hovered: false,
      });
      updateMapPosition({
        isAnimated: !shouldMapBeHidden,
        center: [defaultViewport.latitude, defaultViewport.longitude],
      });
    } else if (!prevSelectedHood && selectedHood) {
      setFeatureState(selectedHood.featureId, {
        clicked: true,
        hovered: false,
      });
      const features = map.queryRenderedFeatures(null, {
        layers: ['neighborhoods-outline'],
        filter: ['==', 'hoodTitle', selectedHood.hoodTitle],
      });

      if (features && features[0]) {
        const [minLng, minLat, maxLng, maxLat] = bbox(features[0]);

        updateMapPosition({
          isAnimated: !shouldMapBeHidden,
          bounds: [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
        });
      }
    }
  }, [
    selectedHood,
    defaultViewport,
    prevSelectedHood,
    map,
    updateMapPosition,
    shouldMapBeHidden,
    setFeatureState,
    isVisible,
  ]);

  const onHover = (event) => {
    let newTooltipData = null;

    tooltipData && setFeatureState(tooltipData.id, { hovered: false });

    const hoveredFeature =
      event.features &&
      event.features.find(
        (feature) => feature.properties && feature.properties.hoodTitle
      );

    if (hoveredFeature) {
      setFeatureState(hoveredFeature.id, { hovered: true });

      newTooltipData = {
        lngLat: event.lngLat,
        properties: hoveredFeature.properties,
        id: hoveredFeature.id,
      };
    }

    setTooltipData(newTooltipData);
  };
  const onHoverWithDebounce = debounce(onHover, 0);

  const onClick = (event) => {
    const clickedFeature =
      event.features &&
      event.features.find(
        (feature) => feature.properties && feature.properties.hoodTitle
      );

    if (clickedFeature) {
      tooltipData && setFeatureState(tooltipData.id, { hovered: false });
      setTooltipData(null);

      dispatch(
        selectHood({
          ...clickedFeature.properties,
          ...(clickedFeature.properties.population && {
            population: JSON.parse(clickedFeature.properties.population),
          }),
          ...(clickedFeature.properties.rentPrice && {
            rentPrice: JSON.parse(clickedFeature.properties.rentPrice),
          }),
        })
      );
    }
  };

  const onResize = () => {
    if (!isVisible || selectedHood) {
      return;
    }
    updateMapPosition();
  };
  const onResizeWithDebounce = debounce(onResize, 250);

  const onLoad = (event) => {
    updateMapPosition();
    setMap(event.target);
    setIsVisible(true);
    dispatch(setMapIsVisible(true));
  };

  const onToggleSwitchClick = () => {
    dispatch(toggleIsMobileMapHidden());
  };

  const getCursor = () => (tooltipData ? 'pointer' : 'default');

  const classNames = classnames(style.container, {
    [style.collapsed]: isMobile && isMobileMapHidden,
  });

  return (
    <div className={classNames}>
      {!isVisible && !shouldMapBeHidden && <Loader />}
      {isMobile && (
        <ToggleSwitch
          className={style.toggleSwitch}
          titleKey="main.map.toggleSwitchTitle"
          onChange={onToggleSwitchClick}
          isChecked={!isMobileMapHidden}
        />
      )}
      <ReactMapGL
        {...viewport}
        {...DEFAULT_SETTINGS}
        height={isMobile ? '250px' : '100%'}
        visible={isMobile ? !isMobileMapHidden && isVisible : isVisible}
        mapStyle={mapStyle}
        onViewportChange={(newViewport) => setViewport(newViewport)}
        onHover={
          !selectedHood && !shouldMapBeHidden ? onHoverWithDebounce : null
        }
        onClick={!selectedHood && !shouldMapBeHidden ? onClick : null}
        onResize={onResizeWithDebounce}
        onLoad={onLoad}
        getCursor={getCursor}
      >
        {!selectedHood && tooltipData && (
          <MapTooltip {...tooltipData} cityTitle={cityTitle} />
        )}
      </ReactMapGL>
    </div>
  );
};

Map.propTypes = {
  geoJson: PropTypes.object.isRequired,
  districts: PropTypes.array.isRequired,
  viewport: PropTypes.object.isRequired,
  bounds: PropTypes.array.isRequired,
  cityTitle: PropTypes.string.isRequired,
};

export default Map;
