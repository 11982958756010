import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'gatsby-plugin-intl';

import style from './ToggleSwitch.module.css';

const ToggleSwitch = ({ className, onChange, titleKey, isChecked }) => {
  const classNames = classnames(style.container, className);

  return (
    <label className={classNames}>
      {/* eslint-disable-next-line */}
      <input type="checkbox" checked={isChecked} onChange={onChange} />
      <i />
      <FormattedMessage id={titleKey} />
    </label>
  );
};

ToggleSwitch.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  titleKey: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
};

export default ToggleSwitch;
