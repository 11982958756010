import React from 'react';
import PropTypes from 'prop-types';

import TotalRating from '../TotalRating';
import Skeleton from '../Skeleton';

import style from './HoodsListItem.module.css';

const HoodsList = ({
  hood,
  onClick,
  districtColor,
  cityTitle,
  showSkeleton,
  onHover,
  isMobile,
}) => {
  const { hoodTitle, districtName, featureId } = hood;

  const containerHandlers = {
    onClick: () => onClick(hood),
    ...(isMobile
      ? {
          onTouchStart: () => onHover(featureId),
          onTouchEnd: () => onHover(null),
        }
      : {
          onMouseEnter: () => onHover(featureId),
          onMouseLeave: () => onHover(null),
        }),
  };

  return (
    <div
      className={style.container}
      key={hoodTitle}
      {...(!showSkeleton && containerHandlers)}
    >
      <div>
        {showSkeleton ? (
          <>
            <Skeleton className={style.titleSkeleton} />
            <div className={style.district}>
              <span className={style.districtColor} />
              <Skeleton className={style.districtSkeleton} />
            </div>
          </>
        ) : (
          <>
            {/* eslint-disable-next-line */}
            <b className={style.hoodTitle}>{hoodTitle}</b>
            <div className={style.district}>
              <span
                className={style.districtColor}
                style={{ backgroundColor: districtColor }}
              />
              {districtName}
            </div>
          </>
        )}
      </div>
      <TotalRating
        cityTitle={cityTitle}
        hoodTitle={hoodTitle}
        smallSize={true}
        forcedSkeleton={showSkeleton}
      />
    </div>
  );
};

HoodsList.propTypes = {
  districtColor: PropTypes.string.isRequired,
  cityTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  hood: PropTypes.object.isRequired,
  showSkeleton: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default React.memo(HoodsList);
