import React, { useEffect, useMemo } from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import kebabCase from 'lodash.kebabcase';

import Button from '../Button';
import TextLink from '../TextLink';
import TotalRating from '../TotalRating';
import RatingList from '../RatingList';
import ResponsiveImage from '../ResponsiveImage';
import TextButton from '../TextButton';
import HoodNumbers from '../HoodNumbers';

import style from './HoodPreview.module.css';

const MOBILE_MAP_SUB_HEADER_HEIGHT = 50;

const makeMapStateToPropsSelector = () =>
  createSelector(
    (state) => state.mapReducer,
    (_, cityTitle) => ({ cityTitle }),
    ({ listScrollTopObject }, { cityTitle }) =>
      listScrollTopObject[cityTitle] || 0
  );

const HoodPreview = ({
  area,
  cityTitle,
  hoodTitle,
  districtName,
  hoodImageUrl,
  slug,
  isMobile,
  population,
  rentPrice,
  cityPopulation,
  cityDensity,
  cityRentPrice,
  countryCode,
  onSelectHood,
}) => {
  const selectDataFromState = useMemo(makeMapStateToPropsSelector, []);
  const listScrollTop = useSelector((state) =>
    selectDataFromState(state, cityTitle)
  );

  useEffect(() => {
    if (isMobile && listScrollTop > MOBILE_MAP_SUB_HEADER_HEIGHT) {
      window.scrollTo({ top: MOBILE_MAP_SUB_HEADER_HEIGHT });
    }
  }, [listScrollTop, isMobile]);

  const hoodId = kebabCase(hoodTitle);
  const hoodUrl = `${slug}${hoodId}/`;

  const onBackLinkClick = () => onSelectHood(null);

  return (
    <div className={style.container}>
      <TextButton
        onClick={onBackLinkClick}
        className={style.backLink}
        titleKey="main.general.backButton"
      />
      <div className={style.titleBlock}>
        <div className={style.titles}>
          <TextLink
            title={hoodTitle}
            className={style.title}
            linkTo={hoodUrl}
          />
          <h3 className={style.subTitle}>{districtName}</h3>
        </div>
        <TotalRating
          cityTitle={cityTitle}
          hoodTitle={hoodTitle}
          className={style.totalRating}
        />
      </div>
      <ResponsiveImage
        className={style.image}
        src={hoodImageUrl}
        height={250}
        alt="hood"
      />
      <HoodNumbers
        cityTitle={cityTitle}
        hoodTitle={hoodTitle}
        area={area}
        countryCode={countryCode}
        population={population}
        rentPrice={rentPrice}
        cityPopulation={cityPopulation}
        cityDensity={cityDensity}
        cityRentPrice={cityRentPrice}
        showFull={false}
      />
      <RatingList
        cityTitle={cityTitle}
        hoodTitle={hoodTitle}
        showFull={false}
        slug={slug}
        className={style.ratingList}
      />
      <div className={style.buttonContainer}>
        <Button
          titleKey="main.hoodPreview.detailViewButton"
          classNames={style.button}
          linkTo={hoodUrl}
        />
      </div>
    </div>
  );
};

HoodPreview.propTypes = {
  cityTitle: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  cityPopulation: PropTypes.number.isRequired,
  cityDensity: PropTypes.number.isRequired,
  cityRentPrice: PropTypes.number.isRequired,
  population: PropTypes.array,
  rentPrice: PropTypes.array,
  isMobile: PropTypes.bool.isRequired,
  onSelectHood: PropTypes.func.isRequired,
  hoodTitle: PropTypes.string,
  districtName: PropTypes.string,
  hoodImageUrl: PropTypes.string,
};

export default HoodPreview;
