import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-map-gl';

import TotalRating from '../TotalRating';

import style from './MapTooltip.module.css';

const MapTooltip = ({
  lngLat,
  properties: { hoodTitle, districtName },
  cityTitle,
}) => {
  return (
    <Popup
      longitude={lngLat[0]}
      latitude={lngLat[1]}
      closeButton={false}
      offsetTop={30}
      offsetLeft={15}
      tipSize={7}
      anchor="top-left"
    >
      <div className={style.container} key={hoodTitle}>
        <div className={style.titles}>
          <b>{hoodTitle}</b>
          <div className={style.district}>{districtName}</div>
        </div>
        <TotalRating
          cityTitle={cityTitle}
          hoodTitle={hoodTitle}
          smallSize={true}
          className={style.rating}
        />
      </div>
    </Popup>
  );
};

MapTooltip.propTypes = {
  lngLat: PropTypes.array.isRequired,
  properties: PropTypes.object.isRequired,
  rating: PropTypes.object,
};

export default MapTooltip;
