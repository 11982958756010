const getMapStyle = ({ geoJson, districts }) => {
  const mapStyles = {
    version: 8,
    name: 'Basic',
    metadata: {
      'mapbox:autocomposite': true,
    },
    sources: {
      composite: {
        url: 'mapbox://mapbox.mapbox-streets-v8',
        type: 'vector',
      },
    },
    sprite: 'mapbox://sprites/mapbox/basic-v8',
    glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
    layers: [
      {
        id: 'land',
        type: 'background',
        layout: {},
        paint: { 'background-color': '#EFF0F0' },
      },
      {
        id: 'landuse_overlay_national_park',
        type: 'fill',
        source: 'composite',
        'source-layer': 'landuse_overlay',
        filter: ['==', 'class', 'national_park'],
        paint: {
          'fill-color': '#dadada',
          'fill-opacity': 1,
        },
      },
      {
        id: 'landuse',
        type: 'fill',
        source: 'composite',
        'source-layer': 'landuse',
        filter: [
          'match',
          ['get', 'class'],
          ['park', 'airport', 'glacier', 'pitch', 'sand'],
          true,
          false,
        ],
        layout: {},
        paint: {
          'fill-color': '#E1E5E5',
          'fill-opacity': ['match', ['get', 'class'], 'glacier', 0.5, 1],
        },
      },
      {
        id: 'waterway',
        type: 'line',
        source: 'composite',
        'source-layer': 'waterway',
        minzoom: 8,
        layout: {
          'line-cap': ['step', ['zoom'], 'butt', 11, 'round'],
          'line-join': 'round',
        },
        paint: {
          'line-color': 'hsl(185, 3%, 83%)',
          'line-width': ['match', ['get', 'class'], ['canal', 'river'], 2, 0.5],
          'line-opacity': ['interpolate', ['linear'], ['zoom'], 8, 0, 8.5, 1],
        },
      },
      {
        id: 'water',
        type: 'fill',
        source: 'composite',
        'source-layer': 'water',
        layout: {},
        paint: { 'fill-color': '#D2D5D5' },
      },
      {
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        type: 'line',
        source: 'composite',
        id: 'road_major',
        paint: {
          'line-color': '#ffffff',
          'line-width': [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary', 'secondary', 'tertiary'],
            1.5,
            [
              'motorway_link',
              'trunk_link',
              'primary_link',
              'secondary_link',
              'tertiary_link',
              'street',
              'street_limited',
            ],
            1.2,
            1,
          ],
        },
        'source-layer': 'road',
      },
      {
        id: 'settlement-minor-label',
        type: 'symbol',
        source: 'composite',
        'source-layer': 'place_label',
        maxzoom: 15,
        filter: [
          'all',
          ['<=', ['get', 'filterrank'], 5],
          ['==', ['get', 'class'], 'settlement'],
          [
            'step',
            ['zoom'],
            true,
            8,
            ['>=', ['get', 'symbolrank'], 11],
            10,
            ['>=', ['get', 'symbolrank'], 12],
          ],
        ],
        layout: {
          'text-line-height': 1.1,
          'text-size': [
            'step',
            ['get', 'symbolrank'],
            15,
            9,
            13,
            10,
            12,
            12,
            11,
            14,
            10.5,
            16,
            9.5,
            17,
            8.5,
          ],
          'icon-image': [
            'step',
            ['zoom'],
            [
              'case',
              ['==', ['get', 'capital'], 2],
              'border-dot-13',
              [
                'step',
                ['get', 'symbolrank'],
                'dot-11',
                9,
                'dot-10',
                11,
                'dot-9',
              ],
            ],
            8,
            '',
          ],
          'text-font': ['Montserrat Regular', 'Arial Unicode MS Regular'],
          'text-anchor': [
            'step',
            ['zoom'],
            ['get', 'text_anchor'],
            8,
            'center',
          ],
          'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
          'text-max-width': 7,
        },
        paint: {
          'text-color': [
            'step',
            ['get', 'symbolrank'],
            '#747B7B',
            11,
            '#93999A',
            16,
            '#ABAFB0',
          ],
          'text-halo-color': '#FFFFFF',
          'text-halo-width': 1,
          'text-halo-blur': 1,
        },
      },
      {
        id: 'road-label',
        type: 'symbol',
        source: 'composite',
        'source-layer': 'road',
        minzoom: 10,
        filter: [
          'step',
          ['zoom'],
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary', 'secondary', 'tertiary'],
            true,
            false,
          ],
          12,
          [
            'match',
            ['get', 'class'],
            [
              'motorway',
              'trunk',
              'primary',
              'secondary',
              'tertiary',
              'street',
              'street_limited',
            ],
            true,
            false,
          ],
        ],
        layout: {
          'text-size': [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary', 'secondary', 'tertiary'],
            10,
            [
              'motorway_link',
              'trunk_link',
              'primary_link',
              'secondary_link',
              'tertiary_link',
              'street',
              'street_limited',
            ],
            9,
            6.5,
          ],
          'text-max-angle': 30,
          'text-font': ['Montserrat Regular', 'Arial Unicode MS Regular'],
          'symbol-placement': 'line',
          'text-padding': 1,
          'text-rotation-alignment': 'map',
          'text-pitch-alignment': 'viewport',
          'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
          'text-letter-spacing': 0.01,
        },
        paint: {
          'text-color': '#747B7B',
          'text-halo-color': '#FFF',
          'text-halo-width': 1,
          'text-halo-blur': 1,
        },
      },
      {
        id: 'poi-label',
        type: 'symbol',
        source: 'composite',
        'source-layer': 'poi_label',
        minzoom: 6,
        filter: ['<=', ['get', 'filterrank'], 3],
        layout: {
          'text-size': ['step', ['get', 'sizerank'], 18, 5, 12],
          'icon-image': '',
          'text-font': ['Montserrat Regular', 'Arial Unicode MS Regular'],
          'text-offset': [0, 0],
          'text-anchor': ['step', ['get', 'sizerank'], 'center', 3, 'top'],
          'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
        },
        paint: {
          'text-halo-color': '#FFF',
          'text-halo-width': 0.5,
          'text-halo-blur': 0.5,
          'text-color': ['step', ['get', 'sizerank'], '#ABAFB0', 3, '#93999A'],
        },
      },
    ],
  };

  mapStyles.sources.neighborhoods = {
    type: 'geojson',
    data: geoJson,
  };

  mapStyles.layers.push(
    {
      id: 'neighborhoods-fill',
      source: 'neighborhoods',
      type: 'fill',
      paint: {
        'fill-color': [
          'match',
          ['string', ['get', 'districtName']],
          ...districts.reduce((acc, { districtName, fillColor }) => {
            acc.push(districtName, fillColor);
            return acc;
          }, []),
          'transparent',
        ],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hovered'], false],
          0.8,
          ['boolean', ['feature-state', 'clicked'], false],
          0,
          0.3,
        ],
      },
    },
    {
      id: 'neighborhoods-outline',
      source: 'neighborhoods',
      type: 'line',
      paint: {
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          5,
          ['boolean', ['feature-state', 'hovered'], false],
          3,
          2,
        ],
        'line-color': '#60495A',
      },
    }
  );

  return mapStyles;
};

export default getMapStyle;
