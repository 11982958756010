import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import kebabCase from 'lodash.kebabcase';
import area from '@turf/area';

import Layout from '../components/Layout';
import MapSubHeader from '../components/MapSubHeader';
import Map from '../components/Map';
import CityDataSection from '../components/CityDataSection';

import { getFlagEmoji } from '../utils/locale-helper';

const City = ({ data }) => {
  const {
    markdownRemark: {
      fields: { slug },
      frontmatter: {
        geoJson,
        bounds,
        districts,
        title,
        viewport,
        population,
        density,
        countryCode,
        rentPrice,
      },
    },
  } = data;

  const { geoJsonFeatures, hoodsList } = useMemo(() => {
    const geoJsonFeatures = [];
    const hoodsList = [];

    geoJson.features.forEach((feature, index) => {
      // TODO: find another way of assigning id to a feature
      const updatedProperties = {
        ...feature.properties,
        area: (area(JSON.parse(feature.geometry)) / 1000000).toFixed(2),
        featureId: index,
      };

      geoJsonFeatures.push({
        ...feature,
        geometry: JSON.parse(feature.geometry),
        id: index,
        properties: updatedProperties,
      });

      hoodsList.push(updatedProperties);
    });

    return { geoJsonFeatures, hoodsList };
  }, [geoJson.features]);

  const parsedGeoJson = {
    ...geoJson,
    features: geoJsonFeatures,
  };
  const boundsCoordinates = JSON.parse(bounds).coordinates;
  const districtsColors = districts.reduce(
    (acc, { districtName, fillColor }) => ({
      ...acc,
      [districtName]: fillColor,
    }),
    {}
  );

  return (
    <Layout
      pageTitle={`${title} ${getFlagEmoji(countryCode)}`}
      pageImage={`/img/${kebabCase(title)}.jpg`}
      slug={slug}
      map
    >
      <MapSubHeader cityTitle={title} countryCode={countryCode} />
      <Map
        viewport={viewport}
        geoJson={parsedGeoJson}
        bounds={boundsCoordinates}
        districts={districts}
        cityTitle={title}
      />
      <CityDataSection
        cityTitle={title}
        countryCode={countryCode}
        slug={slug}
        hoodsList={hoodsList}
        population={population}
        density={density}
        rentPrice={rentPrice}
        districtsColors={districtsColors}
      />
    </Layout>
  );
};

City.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default City;

export const pageQuery = graphql`
  query CityQueryByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        title
        bounds
        countryCode
        population
        density
        rentPrice
        viewport {
          latitude
          longitude
          zoom
        }
        districts {
          fillColor
          districtName
        }
        geoJson {
          type
          features {
            type
            properties {
              hoodTitle
              hoodImageUrl
              population
              rentPrice
              districtName
            }
            geometry
          }
        }
      }
    }
  }
`;
