import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';

import HoodsList from '../HoodsList';
import HoodPreview from '../HoodPreview';

import { selectHood } from '../../state/map/actions';
import useWindowSize from '../../hooks/use-window-size';

import style from './CityDataSection.module.css';

const mapStateToPropsSelector = createSelector(
  (state) => state.mapReducer,
  ({ selectedHood }) => selectedHood
);

const CityDataSection = ({
  slug,
  cityTitle,
  hoodsList,
  countryCode,
  population,
  density,
  rentPrice,
  districtsColors,
}) => {
  const dispatch = useDispatch();
  const selectedHood = useSelector(mapStateToPropsSelector);
  useEffect(() => {
    return () => {
      dispatch(selectHood(null));
    };
  }, [dispatch]);

  const { width } = useWindowSize();
  const isMobile = width <= 600;

  const onSelectHood = useCallback((hood) => dispatch(selectHood(hood)), [
    dispatch,
  ]);

  return (
    <div className={style.container}>
      {!selectedHood ? (
        <HoodsList
          cityTitle={cityTitle}
          hoodsList={hoodsList}
          onSelectHood={onSelectHood}
          districtsColors={districtsColors}
          isMobile={isMobile}
        />
      ) : (
        <HoodPreview
          {...selectedHood}
          countryCode={countryCode}
          onSelectHood={onSelectHood}
          slug={slug}
          cityTitle={cityTitle}
          cityPopulation={population}
          cityDensity={density}
          cityRentPrice={rentPrice}
          isMobile={isMobile}
        />
      )}
    </div>
  );
};

CityDataSection.propTypes = {
  slug: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  cityTitle: PropTypes.string.isRequired,
  population: PropTypes.number,
  density: PropTypes.number,
  rentPrice: PropTypes.number.isRequired,
  hoodsList: PropTypes.array.isRequired,
  districtsColors: PropTypes.object.isRequired,
};

export default CityDataSection;
